.login .login-thumbnail {
  margin: 0 auto;
  text-align: center;
  border-radius: 8px;
}
.login .login-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
  border-radius: 8px;
}
.login .login-info {
  background-color: #fff;
  padding: 24px 1rem;
  border-radius: 10px;
  margin-bottom: 24px;
}
.login-header {
  padding-top: 3rem;
  padding-bottom: 5rem;
  color: #fff;
  font-weight: bold;
}
.login .modal-level-title {
  margin-bottom: 24px;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
}

.login .login-thumbnail2 {
  width: 107px;
  height: 75px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.login .login-thumbnail2 img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
}

.login-title {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(33, 37, 41, 1);
}
